







































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {ILanguage} from '@/types/TranslationTypes';
import TranslationService from '@/services/TranslationService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

const emptyLanguageForm: ILanguage = {
  name: '',
  shortcut: '',
};

@Component({
  name: 'Translations',
  components: {
    LanguageForm: () => import('@/components/Translations/LanguageForm.vue'),
    ConfirmationDialog: () => import('@/components/ConfirmationDialog.vue'),
  },
})
export default class Translations extends Vue {
  @Prop()
  activeLangId!: number | null;

  languages: ILanguage[] = [];

  isLangFormOpened: boolean = false;
  isCreateMode: boolean = false;

  languageForm: ILanguage = emptyLanguageForm;

  langToRemove: ILanguage | null = null;
  removeLangLoading: boolean = false;

  created() {
    this.fetchLanguages();
  }

  async fetchLanguages() {
    try {
      this.languages = await TranslationService.getLangs();
      this.selectLang(this.languages[0]);
    } catch (e) {
      resolveError(e, 'fetch_languages');
    }
  }

  openCreateForm() {
    this.languageForm = emptyLanguageForm;
    this.isLangFormOpened = true;
    this.isCreateMode = true;
  }

  async removeLang() {
    try {
      await TranslationService.deleteLang(this.langToRemove!.id!);
      notify(NotificationTypes.success, this.$t('SYSTEM_LANGUAGE_DELETED'));
      this.langToRemove = null;
      await this.fetchLanguages();
    } catch (e) {
      resolveError(e, 'save');
    }
  }

  editLang(lang: ILanguage) {
    this.languageForm = {...lang};
    this.isLangFormOpened = true;
    this.isCreateMode = false;
  }

  selectLang(lang: ILanguage) {
    this.$emit('langSelected', lang.id);
  }
}
